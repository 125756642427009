var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
		'cv-wrapper',
		'locale-' + _vm.languageCode(_vm.displayLocale),
		'locale-' + _vm.displayLocale,
		'y' + _vm.periodStart.getFullYear(),
		'm' + _vm.paddedMonth(_vm.periodStart),
		'period-' + _vm.displayPeriodUom,
		'periodCount-' + _vm.displayPeriodCount,
		{
			past: _vm.isPastMonth(_vm.periodStart),
			future: _vm.isFutureMonth(_vm.periodStart),
			noIntl: !_vm.supportsIntl,
		} ],attrs:{"aria-label":"Calendar"}},[_vm._t("header",null,{"headerProps":_vm.headerProps}),_c('div',{staticClass:"cv-header-days"},[(_vm.displayWeekNumbers)?_c('div',{staticClass:"cv-weeknumber"}):_vm._e(),_vm._l((_vm.weekdayNames),function(label,index){return [_vm._t("dayHeader",[_c('div',{key:_vm.getColumnDOWClass(index),staticClass:"cv-header-day",class:_vm.getColumnDOWClass(index)},[_vm._v(" "+_vm._s(label)+" ")])],{"index":_vm.getColumnDOWClass(index),"label":label})]})],2),_c('div',{staticClass:"cv-weeks",attrs:{"aria-multiselectable":_vm.enableDateSelection}},_vm._l((_vm.weeksOfPeriod),function(weekStart,weekIndex){return _c('div',{key:(weekIndex + "-week"),class:[
				'cv-week',
				'week' + (weekIndex + 1),
				'ws' + _vm.isoYearMonthDay(weekStart) ]},[(_vm.displayWeekNumbers)?_c('div',{staticClass:"cv-weeknumber"},[_vm._t("weekNumber",[_c('span',[_vm._v(_vm._s(_vm.periodStartCalendarWeek + weekIndex))])],{"date":weekStart,"numberInYear":_vm.periodStartCalendarWeek + weekIndex,"numberInPeriod":weekIndex + 1})],2):_vm._e(),_c('div',{staticClass:"cv-weekdays"},[_vm._l((_vm.daysOfWeek(weekStart)),function(day,dayIndex){return _c('div',{key:_vm.getColumnDOWClass(dayIndex),class:[
						'cv-day',
						_vm.getColumnDOWClass(dayIndex),
						'd' + _vm.isoYearMonthDay(day),
						'd' + _vm.isoMonthDay(day),
						'd' + _vm.paddedDay(day),
						'instance' + _vm.instanceOfMonth(day),
						{
							today: _vm.isSameDate(day, _vm.today()),
							outsideOfMonth: !_vm.isSameMonth(day, _vm.defaultedShowDate),
							past: _vm.isInPast(day),
							future: _vm.isInFuture(day),
							last: _vm.isLastDayOfMonth(day),
							lastInstance: _vm.isLastInstanceOfMonth(day),
							hasItems: _vm.dayHasItems(day),
							selectionStart: _vm.isSameDate(day, _vm.selectionStart),
							selectionEnd: _vm.isSameDate(day, _vm.selectionEnd),
						} ].concat( ((_vm.dateClasses && _vm.dateClasses[_vm.isoYearMonthDay(day)]) || null) ),attrs:{"draggable":_vm.enableDateSelection,"aria-grabbed":_vm.enableDateSelection ? _vm.dayIsSelected(day) : 'undefined',"aria-label":day.getDate(),"aria-selected":_vm.dayIsSelected(day),"aria-dropeffect":_vm.enableDragDrop && _vm.currentDragItem
							? 'move'
							: _vm.enableDateSelection && _vm.dateSelectionOrigin
							? 'execute'
							: 'none'},on:{"click":function($event){return _vm.onClickDay(day, $event)},"dragstart":function($event){return _vm.onDragDateStart(day, $event)},"drop":function($event){$event.preventDefault();return _vm.onDrop(day, $event)},"dragover":function($event){$event.preventDefault();return _vm.onDragOver(day, $event)},"dragenter":function($event){$event.preventDefault();return _vm.onDragEnter(day, $event)},"dragleave":function($event){$event.preventDefault();return _vm.onDragLeave(day, $event)}}},[_c('div',{staticClass:"cv-day-number"},[_vm._v(_vm._s(day.getDate()))]),_vm._t("dayContent",null,{"day":day})],2)}),_vm._l((_vm.getWeekItems(weekStart)),function(i){return [_vm._t("item",[_c('div',{key:i.id,staticClass:"cv-item",class:i.classes,style:(("top:" + (_vm.getItemTop(i)) + ";" + (i.originalItem.style))),attrs:{"draggable":_vm.enableDragDrop,"aria-grabbed":_vm.enableDragDrop ? i == _vm.currentDragItem : 'undefined',"title":i.title},domProps:{"innerHTML":_vm._s(_vm.getItemTitle(i))},on:{"dragstart":function($event){return _vm.onDragItemStart(i, $event)},"mouseenter":function($event){return _vm.onMouseEnterItem(i, $event)},"mouseleave":function($event){return _vm.onMouseLeaveItem(i, $event)},"click":function($event){$event.stopPropagation();return _vm.onClickItem(i, $event)}}})],{"value":i,"weekStartDate":weekStart,"top":_vm.getItemTop(i)})]})],2)])}),0)],2)}
var staticRenderFns = []

export { render, staticRenderFns }